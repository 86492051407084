import React, { Component } from 'react'

export default class blog extends Component {
    render() {
        return (
            <div>
                {/* <h2 className="major">Blog</h2> */}
          {/* <blockquote class="embedly-card"><h4><a href="https://medium.com/@ransing">Viraj R - Medium</a></h4><p>Read writing from Viraj R on Medium. Every day, Viraj R and thousands of other voices read, write, and share important stories on Medium.</p></blockquote>
            <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script> */}

                    <blockquote class="embedly-card" data-card-controls="0" data-card-theme="dark">
                    <a target="_blank" href="https://medium.com/@ransing/behavioral-health-tech-f4a5072bf4ec">
                    <img class="card-img-top" src="https://miro.medium.com/max/1096/1*Bsvx8ehquiJJ-urzdyQg-w.png" href="https://medium.com/@ransing/behavioral-health-tech-f4a5072bf4ec" />
                    </a>
                    <h4><a target="_blank" href="https://medium.com/@ransing/behavioral-health-tech-f4a5072bf4ec"><span style={{"font-size":"25px"}}>Behavioral Health & Tech</span></a></h4><p>The technological advances in the past decade are very well documented. The impact of mental health is also documented in media recently. However, there have not been much significant breakthroughs which have crossed the bridge and linked tech as a tool to help mental health. Question: How can tech influence behavioral health?</p></blockquote>
                    <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>

                    <blockquote class="embedly-card" data-card-controls="0" data-card-theme="dark">
                    <a target="_blank" href="https://medium.com/@ransing/generate-a-mock-online-api-for-javascript-practice-f78f76271bc6?source=---------3------------------">
                    <img class="card-img-top" src="https://miro.medium.com/max/1354/1*iK_TvY-qCOeTAhNz_al6bg.png"/> 
                    </a>
                    <h4><a target="_blank" href="https://medium.com/@ransing/generate-a-mock-online-api-for-javascript-practice-f78f76271bc6?source=---------3------------------"><span style={{"font-size":"25px"}}>Generate a mock online API for Javascript practice</span></a></h4><p>Javascript(JS) is a magnificent language which has been compared to the wild wild west. We can do pretty much anything in JS. With great features comes great headache of learning how those operate. We have come across instances where sometimes you may require a 40 lines code to describe what a button can or would do.</p></blockquote>
                    <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>

                    <blockquote class="embedly-card" data-card-controls="0" data-card-theme="dark">
                    <a  target="_blank" href="https://medium.com/@ransing/the-capabilities-of-nested-routes-18c5980ba12c?source=---------4------------------">
                    <img class="card-img-top" src="https://miro.medium.com/max/300/1*7iQMum5jZnlso-AnxBCLtA.jpeg"/>
                    </a>
                    <h4><a  target="_blank" href="https://medium.com/@ransing/the-capabilities-of-nested-routes-18c5980ba12c?source=---------4------------------"><span style={{"font-size":"25px"}}>The Capabilities of Nested Routes</span></a></h4><p>Rails is supposed to make our navigation towards creating an application easy. Often during creating an application, we have to maneuver ourselves from one part to another. This is where we need Routes. The browser (user) makes a request to the controller using routes.</p></blockquote>
                    <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>

                    <blockquote class="embedly-card" data-card-controls="0" data-card-theme="dark">
                    <a  target="_blank" href="https://medium.com/@ransing/models-and-enumerables-for-beginners-636cfefd9295?source=---------5------------------">
                    <img class="card-img-top" src="https://miro.medium.com/max/1014/1*O8YpOFOaj4FU0ClEACRGAA.png"/>
                    </a>
                    <h4><a  target="_blank" href="https://medium.com/@ransing/models-and-enumerables-for-beginners-636cfefd9295?source=---------5------------------"><span style={{"font-size":"25px"}}>Models and Enumerables for beginners</span></a></h4><p>"A smart model is a good model." ― Tyra Banks In programming a very important concept which should be engraved into our subconscious memory is the understanding of models. Let us begin with the following example. Jerry Seinfeld has many Porsches. All of his Porsches belong to him.</p></blockquote>
                    <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>


            </div>
        )
    }
}
