import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="fa fa-laptop fa-3x" aria-hidden="true" ></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>viraj.info</h1>
        <p style={{"font-size":"17px"}}>
          Full Stack Developer with experience in HealthCare, {' '}Behavioral health, Public Health & Medicine{' '}
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Projects
          </button>
        </li>

        <li>
        <button
            onClick={() => {
              props.onOpenArticle('blog')
            }}
          >
            Blog
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Art
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
        
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
