import React from 'react'
import PropTypes from 'prop-types'
// import CustomChatbot from './CustomChatbot1';

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; 
        {/* Gatsby Starter - Dimension. Design: 
        <a href="https://html5up.net">HTML5 UP</a> */}
        . Built with: <a href="https://www.gatsbyjs.org/">Gatsby.js</a></p>

        {/* <CustomChatbot/> */}
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
