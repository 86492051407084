import React, { Component } from 'react'
import "./Projects.css";
import rhetort from './rhetort.gif';
// import rhetort from './rhetort.png'
// import trailphoto from '/trailphoto.jpg'
import trail from './trail.gif';
import $ from 'jquery';
// import 'node_modules/react-modal-video/scss/modal-video.scss';
import "./modal.scss"
import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video';
import coronacollectiongif from './coronacollectiongif.gif'



export default class Projects extends Component {

        // constructor () {
        //   super()
        //   this.state = {
        //     isOpen: false,
        //     isOpen1: false
        //   }
        //   this.openModal = this.openModal.bind(this),
        //   this.openModal1 = this.openModal1.bind(this)
        // }

        state = {
          isOpen: false,
          isOpenTwo: false
        }

        openModal = () => {
          this.setState({isOpen: true})
        }

        openModalTwo = ()  => {
          this.setState({isOpenTwo: true})
        }

        

  // componentDidMount(){

              
  //               var player;

  //               function loadVideo() {
  //                   player = new YT.Player('player', {
  //                       width: '100%',
  //                       videoId: 'loFtozxZG0s',
  //                   });
  //               }

  //               $('#myModal').on('hide.bs.modal', function (e) {
  //                   player.stopVideo();
  //               });

  //               $('#myModal').on('shown.bs.modal', function (e) {
  //                   player.playVideo();
  //               });

  //               window.onload = loadVideo();
  // }



    render() {
        return (
          <>

<div class="col-12 col-md-6 col-lg-4 mb-4">
    <div class="card">
        <h2>coronacollection</h2>
      <img class="card-img-top" src={coronacollectiongif} alt="Card image cap"/>
      <div class="card-body">
        <p class="card-text"><span style={{"font-size":"20px", "align-items":"center"}}> A collection of COVID-19 applications   </span></p>
        <td class="td-wrapper">
        <div> 
            <ModalVideo channel='youtube' isOpen={this.state.isOpenTwo} videoId='YVA-BuR378s' onClose={() => this.setState({isOpenTwo: false})} />
            {/* <button class="project-button" style={{ "margin-right": "70px" }} 
              onClick={this.openModalTwo}
              type="button" class="btn btn-default" data-toggle="modal" data-target="#myModal"
            > Demo</button>  */}
        </div>
            <a target="_blank" href="https://coronacollection.netlify.app/"> <button class="project-button" style={{ "margin-left": "70px" }} >Visit</button></a>
      </td>
      </div>
    </div>
  </div>
                
            <div>
                <div class="row">
  {/* <!-- the cols in this div change the number of cards per row depending on screen size and the mb-4 adds space below cards if they spill over into the next row--> */}
            <div class="col-12 col-md-6 col-lg-4 mb-4">
            <div class="card">
            <h2>Trail.blzr</h2>
              <img class="card-img-top" src={trail} alt="Card image cap"/>
                      {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/j_NW0a5vQIg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              <div class="card-body">
              <p class="card-text"><span style={{"font-size":"20px", "align-items":"center"}}>A social app to encourage meetups in the great outdoors. Users are able to make events, map them, communicate in real time with others and get predicted weather information. </span></p>
      
              <td class="td-wrapper">
        <div> 
            <ModalVideo allowFullScreen channel='youtube' isOpen={this.state.isOpen} videoId='j_NW0a5vQIg' onClose={() => this.setState({isOpen: false})} />
            <button class="project-button" style={{ "margin-right": "70px" }} 
              onClick={this.openModal}
              type="button" class="btn btn-default" data-toggle="modal" data-target="#myModal"
            > Demo</button>
        </div>

           
                  {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='j_NW0a5vQIg' onClose={() => this.setState({isOpen: false})} /> */}
                  
            <a target="_blank" href="http://trailblzr.surge.sh/"> <button class="project-button" style={{ "margin-left": "70px" }}>Live</button></a>
      </td>
      </div>
    </div>
  </div>

    {/* youtube modal */}

  <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
          >
          <h2 className="major">PROJECTS</h2>
          <span className="image main">
            {/* <img src={pic02} alt="" /> */}
          </span>
           
         
      </article>

  <hr></hr>
        {/* second project */}
  <div class="col-12 col-md-6 col-lg-4 mb-4">
    <div class="card">
        <h2>Rhetort Fort</h2>
      <img class="card-img-top" src={rhetort} alt="Card image cap"/>
      <div class="card-body">
        <p class="card-text"><span style={{"font-size":"20px", "align-items":"center"}}> An entertainment app inspired by the games, Cards Against Humanity and Quiplash. Users submit humorous answers to various questions and vote on the best result.   </span></p>
        <td class="td-wrapper">
        <div> 
            <ModalVideo channel='youtube' isOpen={this.state.isOpenTwo} videoId='YVA-BuR378s' onClose={() => this.setState({isOpenTwo: false})} />
            <button class="project-button" style={{ "margin-right": "70px" }} 
              onClick={this.openModalTwo}
              type="button" class="btn btn-default" data-toggle="modal" data-target="#myModal"
            > Demo</button> 
        </div>
            <a target="_blank" href="http://rhetortfort.surge.sh/"> <button class="project-button" style={{ "margin-left": "70px" }} >Live</button></a>
      </td>
      </div>
    </div>
  </div>


  {/* <div class="col-12 col-md-6 col-lg-4 mb-4">
    <div class="card">
      <img class="card-img-top" src="https://res.cloudinary.com/sepuckett86/image/upload/v1513176676/DSCN2129_copy_cdgcqc.jpg" alt="Card image cap"/>>
            <div class="card-body">
                <p class="card-text">This photo is a different size than the first two.</p>
            </div>
    </div>
  </div> */}
            </div>
            </div>

        </>
        )
    }
}
